// Import Packages
import moment from 'moment'
import * as React from 'react'
import { Button, Form, Loader, Select } from 'semantic-ui-react'

// Import Components
import Modal from 'shared/Modal'
import Toast from 'shared/Toast'
import { handleValidation } from './Validation'

import client from 'queries/apollo'

// Import Graphql Queries
import { GET_OFFICES, GET_OFFICES_DETAILS } from 'queries/graphql/Offices/Queries'
import { GET_1099_REPORT } from 'queries/graphql/Users/Queries'

// Import Store Types, Actions and Reducers
// import { MyReportModule } from 'store/Reports/Types'

// Import Utils
import { getLoggedInUser } from 'utils'

// Import Styled Components
import { Container, StyledForm } from './Styled'

interface Options {
  key: string
  text: string
  value: string
}
interface Props {
  closeModal: () => void
}

interface State {
  animate: boolean
  openFromDate: boolean
  openToDate: boolean
  fromDate: any
  toDate: any
  yearOptions: Options[]
  officeOptions: Options[]
  userOptions: Options[]
  user: any
  reportObj: any
  errors: object
  loader: boolean
  modalLoader: boolean
  userDropdownDisabled : boolean
  selectedYear: Options
}

class Reports1099 extends React.Component<Props, State> {
  public state = {
    animate: true,
    errors: {},
    fromDate: '01/01/2019',
    loader: false,
    modalLoader: false,
    userDropdownDisabled: false,
    officeOptions: [],
    openFromDate: false,
    openToDate: false,
    reportObj: {},
    selectedYear: {
      key: '',
      text: '',
      value: ''
    },
    toDate: moment('12/31/2019'),
    user: {},
    userOptions: [],
    yearOptions: []
  }

  public setYears = () => {
    let startYear = moment().year()
    const years = []
    const endYear = 2015
    while (startYear >= endYear) {
      if (years.length < 5) {
        years.push(startYear--)
      } else {
        return years
      }
    }
    return years
  }

  public async componentDidMount() {
    const { reportObj } = this.state
    let listdata: any = {}
    const years: any = this.setYears()
    const yearData: Options[] = []
    years.forEach((list: any) => {
      const obj: Options = {
        key: list,
        text: list,
        value: list
      }
      yearData.push(obj)
    })
    reportObj[`year`] = Number(moment().year())
    try{
      this.setState({...this.state, modalLoader:true})
      const user: any = await getLoggedInUser({ fromCache: true })
      debugger
      listdata = await client.query({
        fetchPolicy: 'network-only',
        query: GET_OFFICES
      })
      const officeObj: Options[] = listdata.data.getOffices.map((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.branchName,
          value: `${element.branchName}, ${element._id}`
        }
        return obj
      })
      this.setState({
        officeOptions: officeObj,
        reportObj,
        selectedYear: {
          key: reportObj[`year`],
          text: reportObj[`year`],
          value: reportObj[`year`]
        },
        user,
        yearOptions: yearData
      })

    } catch(error){
      Toast({ message: 'Failed in fetching offices', type: 'error' })
      this.closeModal()
    } finally{
      this.setState({...this.state, modalLoader:false})
    }
  }

  public render() {
    const { animate, yearOptions, officeOptions, userOptions, user, errors, loader, selectedYear, modalLoader, userDropdownDisabled } = this.state

    return (
      <Modal
        content={
          <Container>
          <StyledForm size={'tiny'}>
            {modalLoader ? (
              <div className='loader-main'> <Loader active={true}  /></div>
            ) : (
              <>
                {(user['role'] === 'ADMIN' || user['role'] === 'MANAGER') && (
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Select}
                      name="office"
                      label="Offices"
                      search={true}
                      options={officeOptions}
                      placeholder="Select Office"
                      onChange={this.handleChange}
                      error={errors['office'] ? true : false}
                    />
                    <Form.Field
                      control={Select}
                      name="userId"
                      label="Users"
                      search={true}
                      options={userOptions}
                      placeholder="Select User"
                      onChange={this.handleChange}
                      disabled={userDropdownDisabled}
                      error={errors['userId'] ? true : false}
                    />
                  </Form.Group>
                )}
                <Form.Field
                  control={Select}
                  name="year"
                  label="Years"
                  search={true}
                  options={yearOptions}
                  placeholder={'Select Year'}
                  value={selectedYear['value']}
                  onChange={this.handleChange}
                  error={errors['year'] ? true : false}
                />
                <Form.Field control={Button} disabled={loader || userDropdownDisabled} loading={loader || userDropdownDisabled} content={loader ? 'LOADING' : 'Generate Report'} onClick={this.generateReport} />
              </>
            )}
          </StyledForm>
        </Container>        
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={500}
      />
    )
  }

  private handleChange = async (e: any, { name, value }: any) => {
    const { reportObj } = this.state
    if (name === 'office') {
      const result = value.split(', ')
      let listdata: any = {}
      const userObj: Options[] = []
      reportObj[name] = result[1]

      try {
        this.setState({...this.state, userDropdownDisabled:true})
        listdata = await client.query({
          fetchPolicy: 'network-only',
          query: GET_OFFICES_DETAILS,
          variables: { _id: result[1] },
        })
        listdata.data.getOffices.forEach((element: any) => {
          element.users.forEach((items: any) => {
            const obj: Options = {
              key: items._id,
              text: `${items.firstName} ${items.lastName}`,
              value: `${items._id}`,
            }
            userObj.push(obj)
          })
        })
        userObj.unshift({
          key: 'All',
          text: 'All',
          value: 'All',
        })
        this.setState({ userOptions: userObj })
      } catch (error) {
        Toast({ message: 'Failed in fetching offices details', type: 'error' })
      } finally {
        this.setState({...this.state, userDropdownDisabled:false})
      }
    } else if (name === 'year') {
      reportObj[name] = parseInt(value)
      this.setState({
        selectedYear: {
          key: value,
          text: value,
          value: value,
        },
      })
    } else {
      reportObj[name] = value
    }
    this.setState({ reportObj })
  }

  private generateReport = async () => {
    const { reportObj, user } = this.state
    if (user['role'] === 'ADMIN' || user['role'] === 'MANAGER') {
      const result = handleValidation(reportObj)
      this.setState({ errors: result.errors })
      let report: any = {}
      const newData = {
        office: reportObj[`office`],
        userId: reportObj[`userId`] !== 'All' ? reportObj[`userId`] : undefined,
        year: reportObj[`year`]
      }
      if (result.formIsValid) {
        this.setState({ loader: true })
        try {
          report = await client.query({
            fetchPolicy: 'network-only',
            query: GET_1099_REPORT,
            variables: { ...newData }
          })
          if (report) {
            this.setState({ loader: true })
          }
          window.open(report.data.get1099Report, '_blank')
          Toast({ message: 'Generate 1099 report successfully', type: 'success' })
        } catch (error) {
          Toast({ message: 'Generate 1099 report failed', type: 'error' })
        } finally{
            this.setState({ loader: false })
            this.closeModal()
          }
      }
    } else {
      let report: any = {}
      const newData = {
        userId: user[`_id`],
        year: reportObj[`year`]
      }
      this.setState({ loader: true })
      try {
        report = await client.query({
          fetchPolicy: 'network-only',
          query: GET_1099_REPORT,
          variables: { ...newData }
        })
        if (report) {
          this.setState({ loader: true })
        }
        window.open(report.data.get1099Report, '_blank')
        this.closeModal()
        Toast({ message: 'Generate 1099 report successfully', type: 'success' })
      } catch (error) {
        Toast({ message: 'Generate 1099 report failed', type: 'error' })
      }
    }
  }

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }
}

export default Reports1099
