import gql from 'graphql-tag'


export const GET_CAP_SETTING = gql`
  query getCapSettings {
    getCapSettings(where: { isActive: true, isDefault: true }) {
      _id
      name
      isDefault
      isActive
      capStatus
      individualResidentialCap
      individualCommercialCap
      teamCap
      additionalMemberCap
    }
  }
`

export const GET_USER_CAP_SETTING = gql`
  query getCapSettings {
    getCapSettings(where: { isActive: true }) {
      _id
      name
      isDefault
      isActive
      capStatus
      individualResidentialCap
      individualCommercialCap
      teamCap
      additionalMemberCap
    }
  }
`
