import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Icon, Image, Input, Popup, Table } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import Colors from 'design/Colors'
import Money from 'design/images/money.png'
import { Routes, Strings } from 'utils'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'
import * as ProfileActions from 'store/UserProfile/Actions'

import {
  CreditDebitType,
  FeeType,
  PaymentTypeEnum,
} from 'store/Transactions/Types'
import {
  CommissionType,
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'

import DatePicker from 'shared/DatePicker'
import { ShowCreditTab } from 'app/UserProfile/UserProfileComponent'
import LineItems from './LineItems'
import FinancialSummaryCommissionAmount from './FinancialSummaryCommissionAmount'

import {
  getCommissionDisbursement,
  getTransactionCreditDebit,
  getTransactionDetails,
} from 'app/Transactions/Dashboard/TransactionQueries'
import {
  applyTransaction,
  updateTransactionCommission,
} from 'app/Transactions/Dashboard/TransactionMutations'

import {
  Container,
  FinancialSummaryCommissionTableCell,
  FinancialTable,
  StyledPopup,
  StyledTable,
  Thumbs,
} from './Styled'

import { faFileInvoiceDollar, faInfoCircle, faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons'
import { faCalculator, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([
  faCalculator,
  faThumbsDown,
  faThumbsUp,
  faTrashAlt,
  faPencilAlt,
  faCheck,
  faFileInvoiceDollar,
  faInfoCircle
])

interface StoreProps {
  getCommission: (commission: Partial<CommissionType>) => void
  setTotalCommission: (commission: Partial<CommissionType>) => void
  setCreditsData: (data: CreditDebitType[]) => void
  setFeeData: (data: FeeType[]) => void
  setReferralFee: (data: FeeType[]) => void
  setTransaction: (data: TransactionType) => void
  setChangeCreditDebitStatus: (accountID: string, status: string) => void
  setUserCreditDebit: (data: CreditDebitType) => void
  setPercentage: any
  isApproved: boolean
  isReleased: boolean
  creditDebit: CreditDebitType[]
}

interface OwnProps {
  updateCommission: (commission: Partial<CommissionType>) => void
  getCommission: (commission: Partial<CommissionType>) => void
  commission: CommissionType
  user: UserType
  transaction: TransactionType
}

type Props = StoreProps & OwnProps

interface State {
  thumbsup: boolean
  thumbsDown: boolean
  openDatePickerForReceived: boolean
  openDatePickerForExpected: boolean
  commissionReceived: number
  expectedCommission: number
  commissionDisbursement: boolean
  expectedDate: any
  receivedDate: any
}

class Financial extends React.Component<Props, State> {
  public state = {
    expectedDate: '',
    receivedDate: '',
    commissionDisbursement: false,
    commissionReceived: 0,
    expectedCommission: 0,
    openDatePickerForExpected: false,
    openDatePickerForReceived: false,
    thumbsDown: false,
    thumbsup: false
  }

  public componentDidMount() {
    const { commission } = this.props
    this.setState({
      expectedDate: commission.closingDate,
      receivedDate: commission.receivedDate
    })
  }

  public componentWillReceiveProps(nextProps: any) {
    this.setState({
      commissionReceived: nextProps.commission.actualCommission,
      expectedCommission: nextProps.commission.expectedCommission
    })
  }

  public render() {
    const {
      openDatePickerForExpected,
      openDatePickerForReceived,
      expectedCommission,
      commissionReceived,
      expectedDate,
      commissionDisbursement,
      receivedDate
    } = this.state
    const { commission, user, isApproved, isReleased, creditDebit, transaction } = this.props
    const expected = Number(expectedCommission)
    const received = Number(commissionReceived)
    return (
      <Container>
        {creditDebit.length > 0 &&
          (user.role === 'ADMIN' || user.role === 'MANAGER') && (
            <StyledTable singleLine={true}>
              <Table.Header>
                {creditDebit.map((data: any) => (
                  <Table.Row>
                    <Table.HeaderCell className="make">
                      <Popup
                        trigger={<FontAwesomeIcon icon={['fal', 'exclamation-circle']} />}
                        content="Debits"
                        size="mini"
                        position="bottom center"
                      />
                      <Link
                        to={Routes.primary.profile.path + `/${user._id}`}
                        onClick={() => ShowCreditTab()}
                        style={{ color: Colors.DarkBlue200 }}
                      >
                        {`${data.user.firstName} ${data.user.lastName} has a $${Number(
                          data.amount.toFixed(2)
                        ).toLocaleString()} ${data.transactionType}. Apply to transaction?`}
                      </Link>
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ textDecoration: 'underline', color: Colors.DarkBlue200, cursor: 'pointer' }}
                    >
                      <Thumbs>
                        <span onClick={() => this.changeStatus('UP', data._id)}>
                          <Icon name={data.thumbsUp ? 'thumbs up' : 'thumbs up outline'} />{' '}
                        </span>
                        <span onClick={() => this.changeStatus('DOWN', data._id)}>
                          <Icon name={data.thumbsDown ? 'thumbs down' : 'thumbs down outline'} />
                        </span>
                      </Thumbs>
                    </Table.HeaderCell>
                  </Table.Row>
                ))}
              </Table.Header>
            </StyledTable>
          )}
        <StyledTable singleLine={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={transaction.status === 'Closed' && '2'}>
                <div style={{ display: '-webkit-box' }}>
                  <Image src={Money} size={'small'} />
                  {Strings.dashboard.commission.financialSummary}
                </div>
              </Table.HeaderCell>
              {
                transaction.status !== 'Closed' && <Table.HeaderCell
                  className="download"
                  onClick={!commissionDisbursement && this.getCommissionDisbursement}
                >
                  {commissionDisbursement ? (
                    <span> {Strings.dashboard.commission.generating}</span>
                  ) : (
                      <span>
                        <FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} />
                        {Strings.dashboard.commission.generateCDF}
                        <StyledPopup
                          position="bottom center"
                          trigger={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                          content={Strings.dashboard.commission.commissionDisbursementForm}
                        />
                      </span>
                    )}
                </Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {Strings.dashboard.commission.expectedCommission}
              </Table.Cell>
              <FinancialSummaryCommissionTableCell>
                <FinancialSummaryCommissionAmount
                  action={this.updateCommission}
                  commissionName='expectedCommission'
                  editable={this.userCanEditExpectedCommission()}
                  initialAmount={expected}
                  placeholder={Strings.dashboard.commission.expectedCommission}
                  total={transaction.propertyId.price}
                  updatePercentageAmount={this.updatePercentageAmount}
                />
                <span
                  style={{
                    marginRight: 10,
                    fontWeight: 400,
                    color: Colors.DarkBlue200,
                    fontStyle: 'normal',
                  }}
                >
                  {Strings.dashboard.commission.closingOn}
                </span>
                {!isApproved || !isReleased ? (
                  <>
                    <Input
                      className="date"
                      size="mini"
                      placeholder="Closing Date"
                      name="closingDate"
                      value={
                        expectedDate === 'Invalid date' || expectedDate === null ? '' : moment(expectedDate).format('L')
                      }
                      onClick={
                        user.role === 'ADMIN' ||
                        user.role === 'MANAGER' ||
                        user._id === transaction.owner._id
                          ? () => this.handleOpen('Expected')
                          : null
                      }
                      autoComplete="off"
                    />
                    <DatePicker
                      onChangeDate={this.onChangeDateForExpected}
                      open={openDatePickerForExpected}
                      handleOpen={
                        user.role === 'ADMIN' ||
                        user.role === 'MANAGER' ||
                        user._id === transaction.owner._id
                          ? () => this.handleOpen('Expected')
                          : () => null
                      }
                      handleClose={this.handleClose}
                    />
                  </>
                ) : expectedDate === 'Invalid date' || expectedDate === null ? (
                  '-'
                ) : (
                  moment(expectedDate).format('L')
                )}
              </FinancialSummaryCommissionTableCell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{Strings.dashboard.commission.commissionReceived}</Table.Cell>
              <FinancialSummaryCommissionTableCell>
                <FinancialSummaryCommissionAmount
                  action={this.updateCommission}
                  commissionName='actualCommission'
                  editable={this.userCanEditReceivedCommission()}
                  initialAmount={received}
                  placeholder={Strings.dashboard.commission.commissionReceived}
                  total={transaction.propertyId.price}
                  updatePercentageAmount={this.updatePercentageAmount}
                />
                <span
                  style={{
                    marginRight: 10,
                    fontWeight: 400,
                    color: Colors.DarkBlue200,
                    fontStyle: 'normal',
                  }}
                >
                  {Strings.dashboard.commission.receivedOn}
                </span>
                {!isApproved || !isReleased ? (
                  <>
                    <Input
                      className="date"
                      size="mini"
                      placeholder="Received Date"
                      name="receivedDate "
                      value={
                        receivedDate === 'Invalid date' || receivedDate === null ? '' : moment(receivedDate).format('L')
                      }
                      onClick={
                        user.role === 'ADMIN' || user.role === 'MANAGER'
                          ? () => this.handleOpen('Received')
                          : () => null
                      }
                      autoComplete="off"
                    />
                    <DatePicker
                      onChangeDate={this.onChangeDateForReceived}
                      open={openDatePickerForReceived}
                      handleOpen={
                        user.role === 'ADMIN' || user.role === 'MANAGER'
                          ? () => this.handleOpen('Received')
                          : () => null
                      }
                      handleClose={this.handleClose}
                    />
                  </>
                ) : receivedDate === 'Invalid date' || receivedDate === null ? (
                  '-'
                ) : (
                      moment(receivedDate).format('L')
                    )}
              </FinancialSummaryCommissionTableCell>
            </Table.Row>
          </Table.Body>
        </StyledTable>

        <FinancialTable>
          <LineItems
            commission={commission}
            type={PaymentTypeEnum.Debit}
            transaction={transaction}
            user={user}
          />
          <LineItems
            commission={commission}
            type={PaymentTypeEnum.Credit}
            transaction={transaction}
            user={user}
          />
          <LineItems
            commission={commission}
            type={PaymentTypeEnum.Referral}
            transaction={transaction}
            user={user}
          />
        </FinancialTable>
      </Container>
    )
  }

  private getCommissionDisbursement = async () => {
    const { transaction } = this.props
    this.setState({ commissionDisbursement: true })
    const result = await getCommissionDisbursement(transaction._id)
    if (result) {
      window.open(result, 'blank')
      this.setState({ commissionDisbursement: false })
    }
  }

  private handleOpen = (commission: string) => {
    if (commission === 'Received') {
      this.setState({ openDatePickerForReceived: true })
    } else {
      this.setState({ openDatePickerForExpected: true })
    }
  }

  private handleClose = () => {
    this.setState({ openDatePickerForReceived: false, openDatePickerForExpected: false })
  }

  private onChangeDateForReceived = async (date: Date) => {
    const { commission, updateCommission, getCommission } = this.props
    this.setState({ receivedDate: date })
    updateCommission({
      ...commission,
      receivedDate: date
    })
    const response = await updateTransactionCommission({
      _id: commission._id,
      actualCommission: commission.actualCommission,
      closingDate: commission.closingDate,
      expectedCommission: commission.expectedCommission,
      receivedDate: date
    })
    getCommission(response)
    this.handleClose()
  }

  private onChangeDateForExpected = async (date: Date) => {
    const { commission, updateCommission, getCommission } = this.props
    this.setState({ expectedDate: date })
    updateCommission({
      ...commission,
      closingDate: date
    })
    const response = await updateTransactionCommission({
      _id: commission._id,
      actualCommission: commission.actualCommission,
      closingDate: date,
      expectedCommission: commission.expectedCommission,
      receivedDate: commission.receivedDate
    })
    getCommission(response)
    this.handleClose()
  }

  private userCanEditReceivedCommission = () => {
    const { user, isApproved, isReleased } = this.props
    return (
      (user.role === 'ADMIN' || user.role === 'MANAGER') &&
      (!isApproved || !isReleased)
    )
  }

  private userCanEditExpectedCommission = () => {
    const { user, isApproved, isReleased, transaction } = this.props
    return (
      (user.role === 'ADMIN' ||
        user.role === 'MANAGER' ||
        user._id === transaction.owner._id) &&
      (!isApproved || !isReleased)
    )
  }

  private updateCommission = async (commissionName: string, commissionValue: number ) => {
    const { getCommission, setTotalCommission, setTransaction, transaction } = this.props
    const { commission, updateCommission } = this.props
    const commissionRelevantDateName = {
      expectedCommission: 'closingDate',
      actualCommission: 'receivedDate',
    }

    const newCommissionValues: any = {
      [commissionName]: commissionValue
    }

    if (0 === commission[commissionName]) {
      newCommissionValues[commissionRelevantDateName[commissionName]] = new Date()
    }

    updateCommission({ ...commission, ...newCommissionValues})
    const response = await updateTransactionCommission({...newCommissionValues, _id: commission._id,})
    getCommission(response)
    const newData = {
      commissionDue: response.commissionDue,
      totalCredits: response.totalCredits,
      totalDebits: response.totalDebits,
      totalExpenses: response.totalExpenses,
      totalIncome: response.totalIncome,
      totalReferrals: response.totalReferrals
    }

    setTotalCommission(newData)
    const details = await getTransactionDetails(transaction._id)
    setTransaction(details[0])
    this.update(response)
    this.setState<never>({ [commissionName === 'expectedCommission' ? commissionName :  'commissionReceived']: commissionValue })

  }

  private updatePercentageAmount =(percentageValue : number)=>{
    const { setPercentage} = this.props
    setPercentage(percentageValue)

  }

  private update = (response: any) => {
    const { setCreditsData, setFeeData, setReferralFee } = this.props
    const debitsData: any = []
    const creditsData: any = []
    if (response) {
      response.fees.forEach((data: any) => {
        if (data.type === 'Credit') {
          creditsData.push(data)
        } else {
          debitsData.push(data)
        }
      })
    }
    setFeeData(debitsData)
    setCreditsData(creditsData)
    setReferralFee(response.referrals)
  }

  private changeStatus = (status: string, id: string) => {
    const {
      getCommission,
      setChangeCreditDebitStatus,
      setTotalCommission,
      setUserCreditDebit,
      transaction,
    } = this.props

    ConfirmAlert({
      showCancelButton: true,
      title: 'Are you sure?',
      type: 'warning'
    }).then(async result => {
      if (result.value) {
        let response: any = {}
        if (status === 'UP') {
          setChangeCreditDebitStatus(id, 'UP')
          response = await applyTransaction(id, transaction._id, true)
        } else {
          setChangeCreditDebitStatus(id, 'DOWN')
          response = await applyTransaction(id, transaction._id, false)
        }
        getCommission(response)
        const newData = {
          commissionDue: response.commissionDue,
          totalCredits: response.totalCredits,
          totalDebits: response.totalDebits,
          totalExpenses: response.totalExpenses,
          totalIncome: response.totalIncome,
          totalReferrals: response.totalReferrals
        }
        setTotalCommission(newData)
        let creditDebitResponse = await getTransactionCreditDebit(transaction.owner._id)
        creditDebitResponse = creditDebitResponse.map((element: any) => {
          return {
            ...element,
            thumbsDown: false,
            thumbsUp: false
          }
        })
        setUserCreditDebit(creditDebitResponse)
      }
    })
  }
}

const mapStateToProps = (state: AppState) => ({
  creditDebit: state.transactions.creditDebit,
  fee: state.transactions.fee,
  isApproved: state.transactions.isApproved,
  isReleased: state.transactions.isReleased
})

export default connect(
  mapStateToProps,
  {
    setChangeCreditDebitStatus: Actions.changeCreditDebitStatus,
    setCreditsData: Actions.getCreditsData,
    setFeeData: Actions.getFeeData,
    setReferralFee: Actions.getReferralFee,
    setTotalCommission: Actions.totalCommission,
    setTransaction: Actions.getTransactionDetail,
    setUserCreditDebit: ProfileActions.getUserCreditDebit,
    setPercentage: Actions.createPercentageAmount
  }
)(Financial)
