import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import DocumentZone from 'shared/DocumentZone'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import TransactionForm from 'shared/Transaction/TransactionForm'
import { handleValidation } from 'shared/Transaction/TransactionForm/Validation'

import {
  createTransactionDocuments,
  deleteTransactionDocument,
  updateTransactionCommission
} from 'app/Transactions/Dashboard/TransactionMutations'
import {
  getTransactionBoards,
  getTransactionCommission,
  getTransactionDetails
} from 'app/Transactions/Dashboard/TransactionQueries'
import { isPlanAllowed } from 'shared/Billing/Queries'
import {
  createTransactionV2,
  createTransactionDocumentsFrom,
  updateTransaction,
  updateExpectedCommission
} from '../Mutations'
import {
  getDocumentsWithIds,
  getIdxPasses,
  getTransactionCategories,
  getTransactionDocuments,
  getTransactionSubcategories
} from '../Queries'

import { getLoggedInUser, Routes } from 'utils'

import { Container, Section } from './Styled'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import { ActionEnum } from 'shared/DocumentZone/Documents/ActionButtons/Types'
import { PDFDocumentType } from 'shared/PDFViewer/Types'
import {
  ActionEnum as FormActionEnum,
  FormErrorType,
  FormType,
  ModeEnum
} from 'shared/Transaction/TransactionForm/Types'
import {
  CreateTransactionParamsType,
  MutationParamsType
} from 'shared/Transaction/Types'
import { PassStatusEnum } from 'store/Pass/Types'
import { TransactionType, TransitionType } from './Types'
import { CommissionItemValueType, CommissionType } from 'app/Transactions/Details/Types'
import { PaymentNumericEnum } from 'store/Transactions/Types'

interface StoreProps {
  storeDocs: PDFDocumentType[]
  storeTransaction: TransactionType
  officeFilter: string
  percentageAmount: number
  commission: CommissionType
  updateStoreTransaction: (transaction: TransactionType) => void
  updateStoreDocs: (docs: PDFDocumentType[]) => void
  updateCommission: any
  setCommission: (data: Partial<CommissionType>) => void
  setTotalCommission: (commission: Partial<CommissionType>) => void
}

export interface OwnProps {
  transactionDetailView?: boolean
  docIds?: string[]
  onClose: (result: any) => void
  transactionId?: string
  mutationParams?: MutationParamsType
  officeId?: string
  hideDocs?: boolean
}

type Props = OwnProps & StoreProps & RouteComponentProps<{}, {}>

interface State {
  docs: PDFDocumentType[]
  docsPending: PDFDocumentType[]
  form: FormType
  isReady: TransitionType
  isEditing: boolean
  loading: boolean
  loadingMessage: string
  mode: ModeEnum
  transaction: TransactionType
  transition: boolean
  user: any
}

class CreateTransactionModal extends React.Component<Props, State> {
  public state = {
    docs: [] as PDFDocumentType[],
    docsPending: [] as PDFDocumentType[],
    form: {
      errors: {} as FormErrorType
    } as FormType,
    isReady: {} as TransitionType,
    isEditing: false,
    loading: false,
    loadingMessage: '',
    mode: ModeEnum.ManualAdd,
    transaction: {} as TransactionType,
    transition: true,
    user: null
  }

  public componentDidMount = async () => {
    const { docIds, transactionId } = this.props
    this.setState({ isEditing: undefined !== transactionId })

    this.setState({
      loading: true,
      loadingMessage: 'Gathering user data...'
    })

    try{

      let user = await getLoggedInUser({ fromCache: true })
    if (!user) {
      user = {
        role: 'GUEST'
      }
    }

    const transaction = await this.getExistingTransaction()
    const form = this.populateForm(transaction)
    let docs = await this.getExistingDocs()
    let docsPending = [] as PDFDocumentType[]
    if (docIds) {
      this.setState({ loadingMessage: 'Transferring documents...' })
      docsPending = await getDocumentsWithIds(docIds)
      docs = [...docsPending, ...docs]
    }

    let mode = ModeEnum.ManualAdd
    if (transactionId) {
      mode = ModeEnum.ManualEdit
    }

    try {
      // checks whether active user has an idx plan active
      await isPlanAllowed('idx')
      const passes: UserPassType[] = await getIdxPasses()
      const hasActivePass =
        passes.length &&
        passes.find(
          (item: UserPassType) => item.settings.status === PassStatusEnum.Active
        )
      if (hasActivePass) {
        mode = ModeEnum.Idx
      }
    } catch (e) {
      // IDX Mode is not available
    }

    const isReady: TransitionType = {
      form: true
    }

    this.setState({
      docs,
      docsPending,
      form,
      isReady,
      loading: false,
      mode,
      user
    })

    } catch(error){
      serverToast('Something went wrong')
    }

    
  }

  public render() {
    const { hideDocs , percentageAmount} = this.props

    const {
      docs,
      form,
      isReady,
      isEditing,
      loading,
      loadingMessage,
      mode,
      transition
    } = this.state

    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loadingMessage} />}
            <Section
              className="emphasis-container"
              height={100}
              width={hideDocs ? 100 : 45}
            >
              {isReady.form && (
                <TransactionForm
                  disableOfficeSelection={!!isEditing}
                  editing={!!isEditing}
                  form={form}
                  mode={mode}
                  onAction={this.handleAction}
                  percentage={percentageAmount}
                  loading={loading}
                />
              )}
            </Section>

            {!hideDocs && (
              <Section height={100} padding={1} primary={true} width={55}>
                <DocumentZone
                  docs={docs}
                  onAction={this.handleAction}
                  onUploadEach={this.handleFileUpload}
                />
              </Section>
            )}
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={hideDocs ? false : true}
        width={hideDocs ? 500 : 0}
      />
    )
  }

  private handleAction = (action: string, data: any, percentage?:number) => {
    switch (action) {
      case ActionEnum.UpdateOne:
        this.handleDocumentUpdate(data)
        break

      case ActionEnum.Trash:
        this.handleDocumentDelete(data)
        break

      case FormActionEnum.Loading:
        this.setState({ loading: !!data, loadingMessage: data })
        break

      case FormActionEnum.UpdateOne:
        const { name, value } = data
        this.handleFormChange(name, value)
        break

      case FormActionEnum.Submit:
        this.handleSubmitTransaction(percentage)
        break

      default:
        return
    }
  }

  private handleSubmitTransaction = async (percentage : number|undefined) => {
    const {
      hideDocs,
      mutationParams,
      transactionId,
      updateStoreDocs,
      updateStoreTransaction,
      updateCommission,
      commission,
      setCommission,
      setTotalCommission,
    } = this.props
    const { docs, docsPending, form, isEditing, user } = this.state
    const validation = handleValidation(form, isEditing )
    const audio = new Audio(
      'https://onechimp.s3-us-west-2.amazonaws.com/deduction.mp3'
    )

    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      // formToast(validation.errors)
      return
    }

    this.setState({ loading: true, loadingMessage: 'Submitting...' })

    const transaction: any = { ...form }
    let feeObj
    if(percentage){
      feeObj = {...transaction.feeObj, type:PaymentNumericEnum.Percent, fee:percentage}
    } else{
      feeObj = { ...transaction.feeObj } as Omit<CommissionItemValueType, '_id'>
    }
  
    const isUpdate = await this.getExistingTransaction()
    // rounding at 2 decimal places on percentage fee
    if(isUpdate._id){
      if(feeObj.type === PaymentNumericEnum.Percent){
        transaction.fee =  Math.round(transaction.price * feeObj.fee) / 100
      }else{
        try{
          const {expectedCommission } = await getTransactionCommission(isUpdate._id)
          transaction.fee = expectedCommission
        } catch(error){
          //error in fetching expected commission
        } 
      }
    } else{
      updateCommission({...commission, expectedCommission:transaction.feeObj.fee})
      transaction.fee = transaction.feeObj.fee
    }
   

    transaction.isActive = true
    delete transaction.errors
    delete transaction.streetAddress
    delete transaction.feeObj

    try {

      if (isUpdate._id) {
        this.setState({ loadingMessage: 'Updating transaction...' })
        const update = await updateTransaction(isUpdate._id, transaction)
        if (docsPending.length > 0) {
          await this.addDocsPending(docsPending, isUpdate._id)
        }
        if (transactionId) {
          updateStoreTransaction(update)
          if (!hideDocs) {
            updateStoreDocs(docs)
          }
        }
        this.showTransactionDetailView(update[`_id`])

        try{
          const response =  await updateTransactionCommission({expectedCommission:transaction.fee, _id: commission._id})    
          setCommission(response)
          const newData = {
            commissionDue: response.commissionDue,
            totalCredits: response.totalCredits,
            totalDebits: response.totalDebits,
            totalExpenses: response.totalExpenses,
            totalIncome: response.totalIncome,
            totalReferrals: response.totalReferrals
          }
          setTotalCommission(newData)
    
        }catch(error){
          //error in updating transaction Commission
        } finally{
          this.setState({ loading: false })
        }
        
        return this.closeSelf(update)
      }

      this.setState({ loadingMessage: 'Creating transaction...' })
      const boards = await getTransactionBoards({})
      const newBoard = boards.find(
        (board: any) => board.name.toLowerCase() === 'new'
      )

      const params: CreateTransactionParamsType = {
        addBoardId: newBoard._id,
        edit: false,
        index: '',
        show: false,
        transaction,
        user,
        ...mutationParams
      }

      const create = await createTransactionV2(params)
      if (create) {
        audio.play()
        this.addExpectedCommission(transaction.fee, create.payment._id)
        this.showTransactionDetailView(create[`_id`])
      }
      if (create && docsPending.length > 0) {
        await this.addDocsPending(docsPending, create._id)
      }
      if (transactionId) {
        updateStoreTransaction(create)
        updateStoreDocs(docs)
      }
      return this.closeSelf(create)
    } catch (error) {
      serverToast(error)
    } finally{
      this.setState({ loading: false })
    }
  }

  private showTransactionDetailView = (transactionID: string) => {
    const { transactionDetailView } = this.props
    if (transactionDetailView) {
      this.props.history.push({
        pathname: `${Routes.transactions.root}/${transactionID}${
          Routes.transactions.documents.path
        }`
      })
    }
  }

  private handleFormChange = (name: string, value: any) => {
    const { form } = this.state
    form[name] = value
    form.errors = {} as FormErrorType
    this.setState({ form })
  }

  private handleDocumentUpdate = async (doc: PDFDocumentType) => {
    const docs = await this.getExistingDocs()

    let update = [...docs]
    const index = update.findIndex((item: PDFDocumentType) => {
      return item._id === doc._id
    })

    if (index < 0) {
      update = [doc, ...update]
    } else {
      update[index] = doc
    }

    this.setState({ docs: update })
  }

  private handleDocumentDelete = async (doc: PDFDocumentType) => {
    const docs = await this.getExistingDocs()

    const update: any = [...docs]
    const index = update.findIndex((item: any) => item._id === doc._id)
    try {
      update.splice(index, 1)
      await deleteTransactionDocument(doc._id)
      this.setState({ docs: update })
      Toast({ message: 'Your document has been deleted', type: 'success' })
    } catch (error) {
      Toast({
        message: 'An error occurred while deleting the document',
        type: 'error'
      })
    }
  }

  private handleFileUpload = async (file: File) => {
    const docs = await this.getExistingDocs()

    let transaction = await this.getExistingTransaction()
    if (!transaction._id) {
      transaction = await this.createPlaceholderTransaction()
      if (!transaction._id) {
        return
      }
    }

    try {
      const doc = await createTransactionDocuments([file], transaction._id)
      const update = [doc[0], ...docs]
      this.setState({ docs: update })
    } catch (error) {
      serverToast(error)
    }
  }

  private getExistingTransaction = async () => {
    const { storeTransaction, transactionId } = this.props
    const { transaction } = this.state

    if (transaction._id) {
      return transaction
    }

    const storeMatchesSupply =
      transactionId && transactionId === storeTransaction._id
    if (storeMatchesSupply) {
      this.setState({ transaction: storeTransaction })
      return storeTransaction
    }

    if (transactionId) {
      try {
        this.setState({ loadingMessage: 'Gathering transaction data...' })
        const existing = await getTransactionDetails(transactionId)
        this.setState({ transaction: existing[0] })
        return existing[0]
      } catch (error) {
        serverToast(error)
        return {} as TransactionType
      }
    }

    return {} as TransactionType
  }

  private getExistingDocs = async () => {
    const { hideDocs, storeDocs, transactionId } = this.props
    const { docs } = this.state

    if (hideDocs) {
      return [] as PDFDocumentType[]
    }

    if (docs.length > 0) {
      return docs
    }

    const storeMatchesSupply =
      transactionId &&
      storeDocs.length > 0 &&
      storeDocs[0].transaction._id === transactionId
    if (storeMatchesSupply) {
      this.setState({ docs: storeDocs })
      return storeDocs
    }

    if (transactionId) {
      try {
        this.setState({ loadingMessage: 'Gathering transaction documents...' })
        const existing = await getTransactionDocuments(transactionId)
        this.setState({ docs: existing })
      } catch (error) {
        serverToast(error)
        return [] as PDFDocumentType[]
      }
    }

    return [] as PDFDocumentType[]
  }

  private populateForm = (transaction: TransactionType) => {
    const { officeId } = this.props
    const { form } = this.state

    if (!transaction._id) {
      return {
        ...form,
        office: officeId || form.office
      }
    }

    const {
      officeTransactionBoard,
      propertyId: {
        address: { city, state, streetName, streetNumber, zipCode },
        bathrooms,
        bedrooms,
        description,
        mlsId,
        price,
        squareFt,
        subType,
        type
      },
      transactionRole
    } = transaction

    const populateForm: FormType = {
      bathrooms,
      bedrooms,
      city,
      description,
      errors: {} as FormErrorType,
      mlsId,
      office:
        (officeTransactionBoard &&
          officeTransactionBoard.office &&
          officeTransactionBoard.office._id) ||
        form.office,
      price,
      squareFt,
      state,
      streetAddress: `${streetNumber} ${streetName}`,
      streetName,
      streetNumber,
      subType: subType._id,
      transactionRole,
      type: type._id,
      zipCode
    }

    return populateForm
  }

  private addDocsPending = async (
    docsPending: PDFDocumentType[],
    transactionId: string
  ) => {
    const docIds = docsPending.map((item: PDFDocumentType) => item._id)
    if (docIds.length === 0) {
      return
    }
    try {
      await createTransactionDocumentsFrom(docIds, transactionId)
    } catch (error) {
      serverToast(error)
    }
  }

  private addExpectedCommission = async (fee: number, commissionId: string) => {
    try {
      await updateExpectedCommission(fee, commissionId)
    } catch (error) {
      serverToast(error)
    }
  }

  private createPlaceholderTransaction = async () => {
    const { mutationParams } = this.props
    const { form, isEditing } = this.state

    let user: any = this.state.user
    if (!user) {
      user = await getLoggedInUser({ fromCache: true })
      if (!user) {
        user = {
          role: 'GUEST'
        }
      }
    }
    this.setState({ user })

    const transaction: any = { ...form }
    delete transaction.errors
    delete transaction.streetAddress

    const validation = handleValidation(form, isEditing)
    if (!validation.formIsValid) {
      Object.keys(validation.errors).forEach(key => {
        transaction[key] = 'PENDING'
      })
    }

    if (transaction.price === 'PENDING') {
      transaction.price = 0
    }

    if (transaction.transactionRole === 'PENDING') {
      transaction.transactionRole = 'Both'
    }

    if (transaction.type === 'PENDING') {
      try {
        const categories = await getTransactionCategories()
        transaction.type = categories[0]._id
      } catch {
        return null
      }
    }

    if (transaction.subType === 'PENDING') {
      try {
        const subcategories = await getTransactionSubcategories(
          transaction.type
        )
        transaction.subType = subcategories[0]._id
      } catch {
        return null
      }
    }

    delete transaction.feeObj

    transaction.isActive = false

    const boards = await getTransactionBoards({})
    const newBoard = boards.find(
      (board: any) => board.name.toLowerCase() === 'new'
    )

    const params: CreateTransactionParamsType = {
      addBoardId: newBoard._id,
      edit: false,
      index: '',
      show: false,
      transaction,
      user,
      ...mutationParams
    }

    try {
      const placeholder = await createTransactionV2(params)
      this.setState({ transaction: placeholder })
      return placeholder
    } catch (error) {
      serverToast(error)
      return {} as TransactionType
    }
  }

  private closeSelf = (result: any = null) => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({ transition: true })
      onClose(result)
    }, 300)
  }
}

const mapStateToProps = (state: AppState) => ({
  officeFilter: state.transactions.officeFilter,
  storeDocs: state.transactions.documents,
  storeTransaction: state.transactions.transactionDetail,
  percentageAmount: state.transactions.percentageAmount,
  commission: state.transactions.commission
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateStoreDocs: Actions.getTransactionDocuments,
      updateStoreTransaction: Actions.getTransactionDetail,
      updateCommission: Actions.updateCommission,
      setCommission: Actions.getCommission,
      setTotalCommission: Actions.totalCommission,
    }
  )(CreateTransactionModal)
)
