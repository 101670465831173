import { ActionCreator } from 'redux'
import Actions from 'store/Transactions/ActionsTypes'
import {
  CreateTransactionDocuments,
  CreateTransactionWorkflowTask,
  DeleteMultipleTransactionWorkflowTasks,
  DeleteTransactionShareWith,
  DeleteTransactionWorkflowTask,
  DocumentCount,
  Filter,
  GetAllRecentTransactionsAction,
  GetTransactionDocumentChecklist,
  GetTransactionDocuments,
  GetTransactionShareWith,
  GetTransactionWorkflowTask,
  ManagerNotes,
  MessageCount,
  Messages,
  PaymentApprove,
  PaymentRelease,
  PercentageAmountAction,
  PropertyImageAdd,
  PropertyImageDelete,
  PropertyImagesSet,
  PropertyImageType,
  Transaction,
  TransactionAddCredits,
  TransactionAddFee,
  TransactionAddNewBoard,
  TransactionAddNewCard,
  TransactionAddNewManagerNote,
  TransactionAddNewMessage,
  TransactionAddReferralFee,
  TransactionAddWorkflow,
  TransactionCommission,
  TransactionConfetti,
  TransactionCreditDebitStatus,
  TransactionDelete,
  TransactionDeleteCard,
  TransactionDeleteCreditsData,
  TransactionDeleteFeeData,
  TransactionDeleteManagerNote,
  TransactionDeleteMessage,
  TransactionDeleteRecord,
  TransactionDeleteReferralFee,
  TransactionDetails,
  TransactionEditCreditsData,
  TransactionEditFeeData,
  TransactionEditManagerNote,
  TransactionEditMessage,
  TransactionEditOwnership,
  TransactionEditRecord,
  TransactionEditReferralFee,
  TransactionFilter,
  TransactionGetCreditsData,
  TransactionGetFeeData,
  TransactionGetManagerNotes,
  TransactionGetMessageData,
  TransactionGetReferralFee,
  TransactionGetWorkflow,
  TransactionImportData,
  TransactionList,
  TransactionOfficeFilter,
  TransactionOwnerID,
  TransactionPassFilter,
  TransactionReferralFee,
  TransactionResetCardData,
  TransactionResetState,
  TransactionRestoreCard,
  TransactionSearchData,
  TransactionSerachLoader,
  TransactionSetNewBoardName,
  TransactionSetNewDescription,
  TransactionSetNewIcon,
  TransactionShareWith,
  TransactionShowDefaultView,
  TransactionShowDocumentLayout,
  TransactionSortBoardData,
  TransactionToggleNotesModal,
  TransactionToggleTab,
  TransactionTotalCommission,
  TransactionWorkflow,
  TransferTransaction,
  UpdateMultipleTransactionWorkflowTasks,
  UpdateTransactionAllDocumentChecklist,
  UpdateTransactionCommission,
  UpdateTransactionDocumentChecklist,
  UpdateTransactionWorkflowTask,
  UserPassTransactionFilter
} from './Types'

export const getAllRecentTransactions: ActionCreator<
  GetAllRecentTransactionsAction
> = (allTransactions: Transaction[], allListTransactions: any) => {
  return {
    payload: { allTransactions, allListTransactions },
    type: Actions.fetchAllRecentTransactions
  }
}

export const addNewBoard: ActionCreator<TransactionAddNewBoard> = (
  index: number,
  newData: Transaction
) => ({
  payload: {
    index,
    newData
  },
  type: Actions.transactionAddNewBoard
})

export const addNewCard: ActionCreator<TransactionAddNewCard> = (
  id: string,
  index: string,
  newData: TransactionList
) => ({
  payload: {
    id,
    index,
    newData
  },
  type: Actions.transactionAddNewCard
})

export const setNewIcon: ActionCreator<TransactionSetNewIcon> = (
  id: string,
  icon: string
) => ({
  payload: {
    icon,
    id
  },
  type: Actions.transactionSetNewIcon
})

export const setNewDescription: ActionCreator<TransactionSetNewDescription> = (
  id: string,
  description: string
) => ({
  payload: {
    description,
    id
  },
  type: Actions.transactionSetNewDescription
})

export const setNewBoardName: ActionCreator<TransactionSetNewBoardName> = (
  id: string,
  boardName: string
) => ({
  payload: {
    boardName,
    id
  },
  type: Actions.transactionSetNewBoardName
})

export const deleteCard: ActionCreator<TransactionDeleteCard> = (
  boardId: string,
  cardId: string
) => ({
  payload: {
    boardId,
    cardId
  },
  type: Actions.transactionDeleteCard
})

export const deleteRecord: ActionCreator<TransactionDeleteRecord> = (
  cardId: string
) => ({
  payload: {
    cardId
  },
  type: Actions.transactionDeleteRecord
})

export const restoreCard: ActionCreator<TransactionRestoreCard> = () => ({
  type: Actions.transactionRestoreCard
})

export const resetCardData: ActionCreator<TransactionResetCardData> = () => ({
  type: Actions.transactionResetCardData
})

export const addNewMessage: ActionCreator<TransactionAddNewMessage> = (
  newData: Messages[]
) => ({
  payload: {
    newData
  },
  type: Actions.transactionAddNewMessage
})

export const getMessagesData: ActionCreator<TransactionGetMessageData> = (
  data: Messages[]
) => ({
  payload: {
    data
  },
  type: Actions.transactionGetMessageData
})

export const editMessage: ActionCreator<TransactionEditMessage> = (
  id: string,
  newData: Messages[]
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.transactionEditMessage
})

export const deleteMessage: ActionCreator<TransactionDeleteMessage> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.transactionDeleteMessage
})

export const moveTransaction: ActionCreator<TransactionDelete> = (
  id: string,
  status: string,
  newData: any
) => ({
  payload: {
    id,
    newData,
    status
  },
  type: Actions.transactionDelete
})

export const addFee: ActionCreator<TransactionAddFee> = (newData: any) => ({
  payload: {
    newData
  },
  type: Actions.transactionAddFee
})

export const getFeeData: ActionCreator<TransactionGetFeeData> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionGetFeeData
})

export const editFeeDate: ActionCreator<TransactionEditFeeData> = (
  id: string,
  newData: any
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.transactionEditFeeData
})

export const deleteFeeDate: ActionCreator<TransactionDeleteFeeData> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.transactionDeleteFeeData
})

export const addCredits: ActionCreator<TransactionAddCredits> = (
  newData: any
) => ({
  payload: {
    newData
  },
  type: Actions.transactionAddCredits
})

export const getCreditsData: ActionCreator<TransactionGetCreditsData> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionGetCreditsData
})

export const editCreditsData: ActionCreator<TransactionEditCreditsData> = (
  id: string,
  newData: any
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.transactionEditCreditsData
})

export const deleteCreditsData: ActionCreator<TransactionDeleteCreditsData> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.transactionDeleteCreditsData
})

export const editRecord: ActionCreator<TransactionEditRecord> = (
  newData: any
) => ({
  payload: {
    newData
  },
  type: Actions.transactionEditRecord
})

export const addReferralFee: ActionCreator<TransactionAddReferralFee> = (
  newData: TransactionReferralFee[]
) => ({
  payload: {
    newData
  },
  type: Actions.transactionAddReferralFee
})

export const importData: ActionCreator<TransactionImportData> = (
  boardId: string,
  importedData: any
) => ({
  payload: {
    boardId,
    importedData
  },
  type: Actions.transactionImportData
})

export const getReferralFee: ActionCreator<TransactionGetReferralFee> = (
  data: TransactionReferralFee[]
) => ({
  payload: {
    data
  },
  type: Actions.transactionGetReferralFee
})

export const editReferralFee: ActionCreator<TransactionEditReferralFee> = (
  id: string,
  newData: TransactionReferralFee[]
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.transactionEditReferralFee
})

export const deleteReferralFee: ActionCreator<TransactionDeleteReferralFee> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.transactionDeleteReferralFee
})

export const addNewManagerNote: ActionCreator<TransactionAddNewManagerNote> = (
  newData: ManagerNotes[]
) => ({
  payload: {
    newData
  },
  type: Actions.transactionAddNewManagerNote
})

export const getManagerNotes: ActionCreator<TransactionGetManagerNotes> = (
  data: ManagerNotes[]
) => ({
  payload: {
    data
  },
  type: Actions.transactionGetManagerNotes
})

export const editManagerNote: ActionCreator<TransactionEditManagerNote> = (
  id: string,
  newData: ManagerNotes[]
) => ({
  payload: {
    id,
    newData
  },
  type: Actions.transactionEditManagerNote
})

export const deleteManagerNote: ActionCreator<TransactionDeleteManagerNote> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.transactionDeleteManagerNote
})

export const addShareWithUser: ActionCreator<TransactionShareWith> = (
  shareWithObj: any
) => ({
  payload: {
    shareWithObj
  },
  type: Actions.transactionShareWith
})

export const getShareWithUser: ActionCreator<GetTransactionShareWith> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.getTransactionShareWith
})

export const addWorkflow: ActionCreator<TransactionAddWorkflow> = (
  newData: any
) => ({
  payload: {
    newData
  },
  type: Actions.transactionAddWorkflow
})

export const getWorkflow: ActionCreator<TransactionGetWorkflow> = (
  data: TransactionWorkflow[]
) => ({
  payload: {
    data
  },
  type: Actions.transactionGetWorkflow
})

export const deleteTransactionShareWithUser: ActionCreator<
  DeleteTransactionShareWith
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.deleteTransactionShareWith
})

export const getTransactionDetail: ActionCreator<TransactionDetails> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionDetails
})

export const getTransactionWorkflowTask: ActionCreator<
  GetTransactionWorkflowTask
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.getTransactionWorkflowTask
})

export const createTransactionWorkflowTask: ActionCreator<
  CreateTransactionWorkflowTask
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.createTransactionWorkflowTask
})

export const deleteTransactionWorkflowTask: ActionCreator<
  DeleteTransactionWorkflowTask
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.deleteTransactionWorkflowTask
})

export const updateTransactionWorkflowTask: ActionCreator<
  UpdateTransactionWorkflowTask
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.updateTransactionWorkflowTask
})

export const getTransactionDocumentChecklist: ActionCreator<
  GetTransactionDocumentChecklist
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.getTransactionDocumentChecklist
})

export const updateTransactionDocumentChecklist: ActionCreator<
  UpdateTransactionDocumentChecklist
> = (data: number) => ({
  payload: {
    data
  },
  type: Actions.updateTransactionDocumentChecklist
})

export const updateTransactionAllDocumentChecklist: ActionCreator<
  UpdateTransactionAllDocumentChecklist
> = (data: number) => ({
  payload: {
    data
  },
  type: Actions.updateTransactionAllDocumentChecklist
})

export const getTransactionDocuments: ActionCreator<GetTransactionDocuments> = (
  data: any
) => {
  return {
    payload: {
      data
    },
    type: Actions.getTransactionDocuments
  }
}

export const createTransactionDocument: ActionCreator<
  CreateTransactionDocuments
> = (data: any) => ({
  payload: {
    data
  },
  type: Actions.createTransactionDocument
})

export const deleteMutlipleTransactionWorkflowTask: ActionCreator<
  DeleteMultipleTransactionWorkflowTasks
> = (ids: any) => ({
  payload: {
    ids
  },
  type: Actions.deleteMultipleTransactionWorkflowTask
})

export const updateMultipleTransactionWorkflowTask: ActionCreator<
  UpdateMultipleTransactionWorkflowTasks
> = (ids: any, status: string) => ({
  payload: {
    ids,
    status
  },
  type: Actions.updateMultipleTransactionWorkflowTask
})

export const addPropertyImage: ActionCreator<PropertyImageAdd> = (
  image: PropertyImageType
) => ({
  payload: {
    image
  },
  type: Actions.propertyImageAdd
})

export const setPropertyImages: ActionCreator<PropertyImagesSet> = (
  images: PropertyImageType[]
) => ({
  payload: {
    images
  },
  type: Actions.propertyImagesSet
})

export const deletePropertyImage: ActionCreator<PropertyImageDelete> = (
  id: string
) => ({
  payload: {
    id
  },
  type: Actions.propertyImageDelete
})

export const sortData: ActionCreator<TransactionSortBoardData> = (
  boardId: string,
  sortOrder: number
) => ({
  payload: {
    boardId,
    sortOrder
  },
  type: Actions.transactionSortBoardData
})

export const getCommission: ActionCreator<TransactionCommission> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.getCommission
})

export const updateCommission: ActionCreator<UpdateTransactionCommission> = (
  data: any
) =>{
  return ({
  payload: {
    data
  },
  type: Actions.updateCommission
})
} 

export const totalCommission: ActionCreator<TransactionTotalCommission> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.totalCommission
})

export const documentCount: ActionCreator<DocumentCount> = (count: any) => ({
  payload: {
    count
  },
  type: Actions.documentCount
})

export const messageCount: ActionCreator<MessageCount> = (count: any) => ({
  payload: {
    count
  },
  type: Actions.messageCount
})

export const paymentApprove: ActionCreator<PaymentApprove> = (
  isApproved: boolean
) => ({
  payload: {
    isApproved
  },
  type: Actions.paymentApprove
})

export const paymentReleased: ActionCreator<PaymentRelease> = (
  isReleased: boolean
) => ({
  payload: {
    isReleased
  },
  type: Actions.paymentReleased
})

export const toggleNotesModal: ActionCreator<TransactionToggleNotesModal> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.transactionModal
})

export const changeCreditDebitStatus: ActionCreator<
  TransactionCreditDebitStatus
> = (accountId: string, status: string) => ({
  payload: {
    accountId,
    status
  },
  type: Actions.changeCreditDebitStatus
})

export const toggleTab: ActionCreator<TransactionToggleTab> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.transactionTab
})

export const transferTransaction: ActionCreator<TransferTransaction> = (
  cardId: string,
  destinationBoardId: string,
  index: number,
  sourceBoardId: string
) => ({
  payload: {
    cardId,
    destinationBoardId,
    index,
    sourceBoardId
  },
  type: Actions.transferTransaction
})

export const filter: ActionCreator<TransactionFilter> = (data: Filter) => ({
  payload: {
    data
  },
  type: Actions.transactionFilter
})

export const searchLoader: ActionCreator<TransactionSerachLoader> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.transactionSearchLoader
})

export const showDefaultView: ActionCreator<TransactionShowDefaultView> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.transactionShowDefaultView
})

export const searchDataFilter: ActionCreator<TransactionSearchData> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionSearchData
})

export const showLayout: ActionCreator<TransactionShowDocumentLayout> = (
  open: boolean
) => ({
  payload: {
    open
  },
  type: Actions.transactionShowDocumentLayout
})

export const resetState: ActionCreator<TransactionResetState> = () => ({
  type: Actions.resetState
})

export const editOwnership: ActionCreator<TransactionEditOwnership> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionEditOwnership
})

export const showOfficeFilter: ActionCreator<TransactionOfficeFilter> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionOfficeFilter
})

export const showTransaction: ActionCreator<TransactionOwnerID> = (
  data: any
) => ({
  payload: {
    data
  },
  type: Actions.transactionOwnerID
})

export const showUserPassTransactionFilterData: ActionCreator<
  UserPassTransactionFilter
> = (filters: TransactionPassFilter) => ({
  payload: {
    filters
  },
  type: Actions.userPassTransactionFilter
})

export const showConfetti: ActionCreator<TransactionConfetti> = (
  data: boolean
) => ({
  payload: {
    data
  },
  type: Actions.transactionConfetti
})

export const createPercentageAmount: ActionCreator<PercentageAmountAction> = (
  percentage: number
) => {
  return ({
  payload: percentage,
  type: Actions.setPercentageAmount,
})
}
